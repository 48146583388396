var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"grey--text"},[_vm._v(" Productos ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.warhouseMovementsDetail,"loading":_vm.fetchingWarehouseMovementsDetail,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
      'items-per-page-text': 'Filas por página',
      pageText: '{0}-{1} de {2}'
    }},scopedSlots:_vm._u([{key:"item.cant",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs(item.cant))+" ")]}},{key:"item.packingCant",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs(item.packingCant))+" ")]}}],null,true)}),_c('p',[_c('b',[_vm._v("Autor: ")]),_vm._v(" "+_vm._s(_vm.id.userName || '')+" "+_vm._s(_vm.id.userLastName || ''))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }