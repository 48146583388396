<template>
  <sales-table />
</template>

<script>
  import { mapActions, mapState, mapMutations } from 'vuex'
  import SalesTable from '../components/warehouse-returns/SalesTable.vue'
  export default {
    name: 'WarehouseReturns',
    components: {
      SalesTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness', 'actionWarehouse']),
    },

    async created () {
      // await this.fetchWarehouseMovements({ id: this.actionWarehouse })
      // await this.fetchWarehouseInventory({ id: this.actionWarehouse })
      this.setActions(Number(this.$route.params.id))
      this.setEnvironment('warehouse')
      await this.fetchWarehouseConcessionNoApprove({ id: this.$route.params.id || this.actionWarehouse, noApprove: true })
      await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductLine({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductClassification({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductCategory({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductType({ id: this.user.businessId || this.actionsParentBusiness })
    },

    methods: {
      ...mapMutations({
        setEnvironment: 'SET_ACTIONS_ENVIRONMENT',
        setActions: 'SET_ACTIONS_WAREHOUSE',
      }),
      ...mapActions([
        'fetchWarehouseConcessionNoApprove',
        // 'fetchWarehouseMovements',
        // 'fetchWarehouseInventory',
        // 'fetchProductLine',
        // 'fetchProductClassification',
        // 'fetchProductCategory',
        // 'fetchProductType',
        'fetchProducts',
        'getWarehousesByParentId',
      ]),
    },
  }
</script>
