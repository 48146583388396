<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-stamper"
          title="Devoluciones por aprobar"
          class="px-5 py-3"
          color="orange"
        >
          <!-- <pre>{{ warhouseMovements }}</pre> -->
          <v-data-table
            :headers="headers"
            :loading="fetchingBusinessSales"
            :items="businessSales"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <sales-table-top />
            </template>
            <template v-slot:[`item.description`]="{ item: input }">
              <span v-if="input.description">
                {{ input.description }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.total`]="{ item: input }">
              <span>
                {{ input.totalAmountCurrencyReference | formatPrice }}
              </span>
            </template>
            <template v-slot:[`item.tr`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.tk`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item: input }">
              {{ new Date(input.createdAt).getDate() }}/{{ new Date(input.createdAt).getMonth() + 1 }}/{{ new Date(input.createdAt).getFullYear() }}
            </template>

            <template v-slot:[`item.approved`]="{ item: item }">
              <v-icon
                v-if="item.approved"
                color="green"
              >
                mdi-check-all
              </v-icon>

              <v-icon
                v-else
                color="gray"
              >
                mdi-check
              </v-icon>
              <!-- <h1>hioh</h1> -->
            </template>

            <template v-slot:[`item.actions`]="{ item: item }">
              <sales-actions
                :item="item"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import SalesTableTop from './SalesTableTop'
  import SalesActions from './SalesActions.vue'
  // import ConcessionRequestActions from './ConcessionRequestActions.vue'

  export default {
    name: 'ReturnsTable',

    components: {
      SalesTableTop,
      SalesActions,
      // ConcessionRequestActions,
    },

    filters: {
      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },
    },

    data () {
      return {
        headers: [
          {
            text: 'Fecha del movimiento',
            value: 'createdAt',
          },
          {
            text: 'Codigo',
            value: 'code',
          },
          {
            text: 'Descripcion',
            value: 'description',
          },
          {
            text: 'Estatus de la solicitud',
            value: 'approved',
          },
          {
            text: 'Total Rollos',
            value: 'tr',
          },
          {
            text: 'Total KG',
            value: 'tk',
          },
          {
            text: 'Total',
            value: 'total',
          },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },
    computed: {
      ...mapState([
        'fetchingBusinessSales',
        'businessSales',
      ]),
    },
  }
</script>
